@import "./_alert.css";
@import "./_avatar.css";
@import "./_badge.css";
@import "./_button.css";
@import "./_card.css";
@import "./_checkbox.css";
@import "./_close-button.css";
@import "./_date-picker.css";
@import "./_dialog.css";
@import "./_drawer.css";
@import "./_dropdown.css";
@import "./_form.css";
@import "./_input-group.css";
@import "./_input.css";
@import "./_menu-item.css";
@import "./_menu.css";
@import "./_notification.css";
@import "./_pagination.css";
@import "./_progress.css";
@import "./_radio.css";
@import "./_segment.css";
@import "./_select.css";
@import "./_skeleton.css";
@import "./_steps.css";
@import "./_switcher.css";
@import "./_tables.css";
@import "./_tabs.css";
@import "./_tag.css";
@import "./_time-input.css";
@import "./_timeline.css";
@import "./_toast.css";
@import "./_tooltip.css";
@import "./_upload.css";

/* Custom css */

.PhoneInput {
  display: flex;
}

.PhoneInputCountry {
  display: flex;
}
.PhoneInputCountrySelect {
  max-width: 100px;
}

.PhoneInputCountry img {
  width: 35px;
  display: none;
}
/* For tree dropdown => product category */
div[id^="rdts"] .tag-item .search {
  border-bottom: none !important;
}

div[id^="rdts"] .dropdown.radio-select {
  width: 100% !important;
}

div[id^="rdts"] a.dropdown-trigger {
  width: 100% !important;
  display: flex;
  height: 2.75rem;
  border-radius: 0.375rem;
  overflow: hidden !important;
  border: 1px solid #d1d5db;
  padding: 0px !important;
}

div[id^="rdts"] ul.tag-list {
  display: inline-block !important;
  width: 100%;
  padding: 5px;
}

div[id^="rdts"] .dropdown .dropdown-trigger.arrow.bottom:after,
div[id^="rdts"] .dropdown .dropdown-trigger.arrow.top:after {
  content: "" !important;
  display: block !important;
  width: 20px !important; /* Adjust the size as needed */
  height: 20px !important; /* Adjust the size as needed */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg stroke=%22rgba%2860%2C%2060%2C%2060%2C%200.8%29%22 fill=%22rgba%2860%2C%2060%2C%2060%2C%200.8%29%22 stroke-width=%220%22 viewBox=%220 0 20 20%22 height=%221em%22 width=%221em%22 xmlns=%22http://www.w3.org/2000/svg%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z%22 clip-rule=%22evenodd%22%3E%3C/path%3E%3C/svg%3E");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin-right: 0 !important;
  position: absolute !important;
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%) !important;
}

div[id^="rdts"] .dropdown-content {
  width: 100% !important;
}

div[id^="rdts"] .tag-remove {
  padding: 0.9px 3px 0.9px 10px;
  font-size: 98% !important;
  color: #6b7280 !important;
}

div[id^="rdts"] ul.tag-list span.tag {
  padding: 5px;
  border-radius: 7px;
  border: none;
  background-color: #f3f4f6;
  font-size: 100%;
  color: #6b7280 !important;
}

div[id^="rdts"] .tag-item,
div[id^="rdts"] .tag-item input {
  min-width: fit-content;
}

/* div[id^="rdts"] li.node.tree .toggle {
  margin-right: 15px !important;
} */

div[id^="rdts"] .toggle.collapsed:after {
  content: "" !important;
  background-image: url("data:image/svg+xml;base64,Cjxzdmcgc3Ryb2tlPSJyZ2JhKDYwLCA2MCwgNjAsIDAuOCkiIGZpbGw9InJnYmEoNjAsIDYwLCA2MCwgMC44KSIgc3Ryb2tlLXdpZHRoPSIwIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiIgaGVpZ2h0PSIxZW0iIHdpZHRoPSIxZW0iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI4NS40NzYgMjcyLjk3MUw5MS4xMzIgNDY3LjMxNGMtOS4zNzMgOS4zNzMtMjQuNTY5IDkuMzczLTMzLjk0MSAwbC0yMi42NjctMjIuNjY3Yy05LjM1Ny05LjM1Ny05LjM3NS0yNC41MjItLjA0LTMzLjkwMUwxODguNTA1IDI1NiAzNC40ODQgMTAxLjI1NWMtOS4zMzUtOS4zNzktOS4zMTctMjQuNTQ0LjA0LTMzLjkwMWwyMi42NjctMjIuNjY3YzkuMzczLTkuMzczIDI0LjU2OS05LjM3MyAzMy45NDEgMEwyODUuNDc1IDIzOS4wM2M5LjM3MyA5LjM3MiA5LjM3MyAyNC41NjguMDAxIDMzLjk0MXoiPjwvcGF0aD48L3N2Zz4K");
  display: inline-block;
  width: 16px;
  height: 16px;
}

div[id^="rdts"] .toggle.expanded:after {
  content: "" !important;
  background-image: url("data:image/svg+xml;base64,Cjxzdmcgc3Ryb2tlPSJyZ2JhKDYwLCA2MCwgNjAsIDAuOCkiIGZpbGw9InJnYmEoNjAsIDYwLCA2MCwgMC44KSIgc3Ryb2tlLXdpZHRoPSIwIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiIgaGVpZ2h0PSIxZW0iIHdpZHRoPSIxZW0iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwNy4wMjkgMzgxLjQ3NkwxMi42ODYgMTg3LjEzMmMtOS4zNzMtOS4zNzMtOS4zNzMtMjQuNTY5IDAtMzMuOTQxbDIyLjY2Ny0yMi42NjdjOS4zNTctOS4zNTcgMjQuNTIyLTkuMzc1IDMzLjkwMS0uMDRMMjI0IDI4NC41MDVsMTU0Ljc0NS0xNTQuMDIxYzkuMzc5LTkuMzM1IDI0LjU0NC05LjMxNyAzMy45MDEuMDRsMjIuNjY3IDIyLjY2N2M5LjM3MyA5LjM3MyA5LjM3MyAyNC41NjkgMCAzMy45NDFMMjQwLjk3MSAzODEuNDc2Yy05LjM3MyA5LjM3Mi0yNC41NjkgOS4zNzItMzMuOTQyIDB6Ij48L3BhdGg+PC9zdmc+Cg==");
  display: inline-block;
  width: 16px;
  height: 16px;
}

div[id^="rdts"] input.radio-item {
  /* width: 15px;
  height: 15px;
  margin-right: 6px !important; */
  visibility: hidden;
  margin: 0 !important;
}

div[id^="rdts"] .node > label {
  margin-left: 0 !important;
}

.image-gallery-thumbnail {
  width: 40px !important;
}

.border-none {
 border-width:  0px !important;
}

.article-table-container td {
  padding: 0.75rem !important;
}

.max-w-220 {
  max-width:  220px !important;
}

.range-slider .range-slider__range, .range-slider .range-slider__thumb {
  background: #4f46e5 !important ;
}

.flex-1 {
  flex: 1
}
.flex-2 {
  flex: 2
}
.flex-3 {
  flex: 3
}