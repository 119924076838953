.dialog {
  outline: none;
  @apply mx-auto;
}

@screen sm {
  .dialog {
    @apply max-w-xl;
  }
}

@screen md {
  .dialog {
    @apply max-w-2xl;
  }
}

@screen lg {
  .dialog {
    @apply max-w-4xl;
  }
}

@screen xl {
  .dialog {
    @apply max-w-6xl;
  }
}

.dialog-content {
  @apply p-6 rounded-lg shadow-xl sm:my-16 relative h-full bg-white dark:bg-gray-800;
}

.dialog-overlay {
  transition: all 0.15s ease-in-out;
  z-index: 120;
  @apply bg-opacity-60 inset-0 fixed z-50 bg-black dark:bg-opacity-80;
}

.dialog-overlay-after-open {
  opacity: 1;
}

.dialog-overlay-before-close {
  opacity: 0;
}
