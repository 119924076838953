@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-500 dark:text-gray-400 text-sm bg-gray-100 dark:bg-gray-900 leading-normal;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  .h1 {
    @apply text-4xl font-bold text-gray-900 dark:text-gray-100;
  }

  h2,
  .h2 {
    @apply text-3xl font-bold text-gray-900 dark:text-gray-100;
  }

  h3,
  .h3 {
    @apply text-2xl font-semibold text-gray-900 dark:text-gray-100;
  }

  h4,
  .h4 {
    @apply text-xl font-semibold text-gray-900 dark:text-gray-100;
  }

  h5,
  .h5 {
    @apply text-lg font-semibold text-gray-900 dark:text-gray-100;
  }

  h6,
  .h6 {
    @apply text-base font-semibold text-gray-900 dark:text-gray-100;
  }

  hr {
    @apply border-gray-200 dark:border-gray-600;
  }

  .heading-text {
    @apply text-gray-900 dark:text-gray-100;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.establishment-modal-body .select__control {
  border-color: #d1d5db !important;
}

.establishment-modal-body .error-shadow .select__control {
  box-shadow: 0 0 0 0px #d1d5db !important;
}

.establishment-modal-phonenumber-error {
  margin-top: -15px;
  margin-bottom: 10px;
  color: #ef4444;
}

.unit-modal-body .form-item {
  margin-bottom: 1rem !important;
}

.disableTreeSelect .tag-list {
  background: #efefef4d !important;
}

.disableTreeSelect .tag-list .tag-item {
  cursor: not-allowed !important;
}

.disableTreeSelect .tag-list input {
  cursor: not-allowed !important;
}
